import { useRuntimeConfig, createError } from '#imports'

export const useAzBlobUrl = () => {
  const config = useRuntimeConfig()
  const containers = config.public.azure.storage.containers

  if (typeof containers === 'undefined')
    throw createError({
      statusCode: 500,
      statusMessage:
        'Azure containers are not defined in nuxt.config:config.public.azure.storage.hot.constainers',
    })

  /**
   * return Azure blob storage url
   */
  const getBlobUrl = (containerPath: string, blobName: string) => {
    let baseUrl, containerName
    const array = containerPath.split('.')
    if (array) {
      containerName = array[1]
      switch (array[0]) {
        case 'hot':
          baseUrl = config.public.azure.storage.hotUrl
          break
        case 'cool':
          baseUrl = config.public.azure.storage.coolUrl
          break
        default:
          throw new Error('Invalid container path')
      }
      return baseUrl.endsWith('/')
        ? `${baseUrl}${containerName}/${blobName}`
        : `${baseUrl}/${containerName}/${blobName}`
    } else throw new Error('Invalid container path')
  }

  /**
   * return Azure blob image url
   */
  const getImageUrl = (blobName: string) => {
    if (!(blobName && blobName.length)) return ''
    if (blobName.startsWith('http')) return blobName
    return getBlobUrl(containers.images, blobName)
  }

  /**
   * return Azure blob  audio demo url
   */
  const getAudioDemoUrl = (blobName: string) => {
    if (!(blobName && blobName.length)) return ''
    if (blobName.startsWith('http')) return blobName
    return getBlobUrl(containers.demos, blobName)
  }

  return {
    getBlobUrl,
    getImageUrl,
    getAudioDemoUrl,
  }
}
